import { useTracking } from 'react-tracking'
interface Product {
  name: string
  description: string
  innovation: boolean
  segment: string
  category: string
  subCategory?: string
  url: string
}

type Props = {
  product: Product
  type: 'modal' | 'search'
}

const Card = ({ product, type }: Props) => {
  const { trackEvent } = useTracking()

  const Title = () => {
    return (
      <div className="flex flex-col-reverse md:flex-row gap-4 md:gap-6 mb-4">
        <h3 className="font-serif text-xl text-red grow">{product.name}</h3>
        {product.innovation && (
          <div className="shrink-0">
            <span className="bg-gold rounded-md p-2 text-black text-xs font-sans font-semibold uppercase inline-flex">
              <svg
                className="fill-current mr-2 h-4 w-4"
                width="16"
                height="15"
                viewBox="0 0 16 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8 0L10.3135 4.81571L15.6085 5.52786L11.7434 9.21629L12.7023 14.4721L8 11.936L3.29772 14.4721L4.25664 9.21629L0.391548 5.52786L5.68648 4.81571L8 0Z" />
              </svg>
              Innovative Product
            </span>
          </div>
        )}
      </div>
    )
  }

  if (type === 'search') {
    return (
      <a
        href={product.url}
        target="_blank"
        rel="noopener noreferrer"
        className={`bg-white rounded-md p-6 border-4 ${
          product.innovation
            ? 'border-gold shadow-lg shadow-gold'
            : 'border-white'
        } hover:border-red`}
        onClick={() => {
          trackEvent({
            component: 'search',
            event: 'link',
            action: 'click',
            label: product.name,
          })
        }}
      >
        <Title />
        <p className="text-sm">
          {product.segment}{' '}
          <svg
            className="inline-block mr-1"
            width="6"
            height="8"
            viewBox="0 0 6 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 1L4 4L1 7" stroke="#E80033" strokeWidth="2" />
          </svg>
          {product.category}{' '}
          {product.subCategory !== '' && (
            <>
              <svg
                className="inline-block mr-1"
                width="6"
                height="8"
                viewBox="0 0 6 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1 1L4 4L1 7" stroke="#E80033" strokeWidth="2" />
              </svg>
              {product.subCategory}
            </>
          )}
        </p>
      </a>
    )
  } else {
    return (
      <div
        className={`bg-white rounded-md p-6 border-4 ${
          product.innovation
            ? 'border-gold shadow-lg shadow-gold'
            : 'border-white'
        }`}
      >
        <Title />
        <div className="flex flex-col md:flex-row md:items-start gap-6">
          <p>{product.description}</p>
          <div className="shrink-0 text-right">
            <a
              className="block text-blue hover:underline"
              href={product.url}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                trackEvent({
                  component: 'modal',
                  event: 'link',
                  action: 'click',
                  label: `Discover more :: ${product.name} :: ${product.url}`,
                })
              }}
            >
              Discover More
            </a>
            <a
              className="block text-blue hover:underline"
              href="https://www.dow.com/en-us/contact-us.html"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                trackEvent({
                  component: 'modal',
                  event: 'link',
                  action: 'click',
                  label: `Reqeust Sample :: ${product.name} :: https://www.dow.com/en-us/contact-us.html`,
                })
              }}
            >
              Request Sample
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default Card
