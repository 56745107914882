import { useState, useEffect } from 'react'
import icon from '../assets/mobile-landscape.svg'
import { isDesktop } from 'react-device-detect'

const isPortrait = () => {
  return window.matchMedia('(orientation: portrait)').matches
    ? 'portrait'
    : 'landscape'
}

const Warning = () => {
  const [orientation, setOrientation] = useState(isPortrait())

  useEffect(() => {
    const handleChange = () => {
      setOrientation(isPortrait())
    }
    window.addEventListener('resize', handleChange)
    return () => window.removeEventListener('resize', handleChange)
  }, [])

  if (isDesktop || orientation === 'landscape') {
    return null
  }

  return (
    <div className="bg-white/60 backdrop-blur-lg text-black flex flex-col items-center justify-center w-screen min-h-screen absolute top-0 left-0 z-50">
      <img className="w-auto h-16 mx-auto mb-10" src={icon} alt="" />
      <p className="font-sans text-base mb-2 px-4 text-center">
        For the best experience, please view in landscape.
      </p>
    </div>
  )
}

export default Warning
