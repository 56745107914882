import Loading from './loading'
import logo from '../assets/logo.svg'
import { useTracking } from 'react-tracking'

interface Props {
  isLoaded: boolean
  loadingProgression: number
  handleShowIntro: (state: boolean) => void
}

const Intro = ({ isLoaded, loadingProgression, handleShowIntro }: Props) => {
  const { trackEvent } = useTracking()

  const handleClick = () => {
    handleShowIntro(false)

    trackEvent({
      component: 'intro',
      event: 'component',
      action: 'click',
      label: 'Start',
    })
  }

  return (
    <div className="bg-white backdrop-blur-lg text-black flex flex-col items-center justify-center w-screen min-h-screen absolute top-0 left-0 z-50">
      <div className="container mx-auto px-4 py-8 text-center">
        <img
          className="w-auto h-8 lg:h-11 mx-auto mb-10"
          src={logo}
          width="140px"
          height="44px"
          alt=""
        />
        <h2 className="font-serif text-5xl mb-6">
          Welcome to our Visual Product Finder
        </h2>
        <p className="font-sans font-semibold text-lg mb-2 mx-auto w-full max-w-5xl">
          Take a tour. Find the product you&apos;re looking for in just a few
          clicks
        </p>
        <p className="font-sans text-lg mb-2 mx-auto w-full max-w-5xl">
          At Dow Construction Chemicals we develop innovative technologies to
          enhance performance, durability, aesthetics, and sustainability across
          the entire built environment.
        </p>
        <p className="font-sans text-lg mb-6 mx-auto w-full max-w-5xl">
          Explore our domestic construction solutions. Follow the link below and
          use the navigation bar and hotspots to move through the 3D house
          environment.
        </p>
        <p className="font-sans text-base mb-6 underline text-center mx-auto w-full max-w-5xl">
          Note: this site is best viewed on desktop.
        </p>
        <div className="h-20 text-center">
          {isLoaded ? (
            <button
              type="button"
              onClick={handleClick}
              className="inline-block rounded border-2 bg-white border-red px-6 pt-2 pb-[6px] text-base font-medium uppercase leading-normal text-red transition duration-150 ease-in-out hover:border-red focus:ring-0"
            >
              Start
            </button>
          ) : (
            <Loading loadingProgression={loadingProgression} />
          )}
        </div>
      </div>
    </div>
  )
}

export default Intro
