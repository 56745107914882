import { useEffect } from 'react'
import products from '../data/products.json'
import Card from './card'
import { useTracking } from 'react-tracking'

interface Props {
  id: number
  handleShowModal: (state: boolean) => void
}

const Modal = ({ id, handleShowModal }: Props) => {
  const { trackEvent } = useTracking()

  useEffect(() => {
    const close = (e: { key: string }) => {
      if (e.key === 'Escape') {
        handleShowModal(false)
      }
    }
    window.addEventListener('keydown', close)

    return () => window.removeEventListener('keydown', close)
  }, [])

  const categoryProducts = products.filter(
    (product) => product.categoryId === id
  )

  if (categoryProducts.length === 0) {
    return null
  }

  const category = {
    segment: categoryProducts[0].segment,
    category: categoryProducts[0].category,
    image: categoryProducts[0].image,
  }

  const handleClick = () => {
    handleShowModal(false)
    trackEvent({
      component: 'modal',
      event: 'component',
      action: 'click',
      label: 'Close Modal',
    })
  }

  return (
    <div className="absolute top-0 left-0 z-20 w-screen min-h-screen bg-white/60 backdrop-blur-lg">
      <div className="container mx-auto px-4 my-16 pt-10">
        <div className="grid grid-cols-1 gap-10 lg:grid-cols-2">
          <div className="mb-4">
            <button
              className="text-lg hover:underline flex flex-row items-center mb-10"
              onClick={handleClick}
            >
              <svg
                className="inline-block mr-2"
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M7 11L2 6L7 1" stroke="#E80033" strokeWidth="2" />
              </svg>
              Back
            </button>
            <h2 className="font-serif text-5xl mb-4">{category.segment}</h2>
            <p className="font-sans text-lg mb-6">{category.category}</p>
            <img className="w-full" src={category.image} />
          </div>
          <div>
            <div className="grid grid-cols-1 gap-4 overflow-auto lg:pt-16">
              {categoryProducts.map((product) => (
                <Card key={product.id} product={product} type="modal" />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
