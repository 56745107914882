import { useState } from 'react'
import logo from '../assets/logo.svg'
import { useTracking } from 'react-tracking'

interface Props {
  handleMenu: (id: number) => void
  handleShowSearch: (state: boolean) => void
}

const Header = ({ handleMenu, handleShowSearch }: Props) => {
  const [open, setOpen] = useState(false)
  const [active, setActive] = useState(0)
  const { trackEvent } = useTracking()

  const links = [
    {
      id: 0,
      label: 'Exterior',
    },
    {
      id: 2,
      label: 'Living room',
    },
    {
      id: 1,
      label: 'Kitchen',
    },
    {
      id: 3,
      label: 'Bathroom',
    },
  ]

  const Links = () => (
    <>
      {links.map((link, i) => (
        <button
          key={link.label}
          title={link.label}
          className={`relative p-2 text-sm font-medium uppercase text-black ${
            active === links[i].id
              ? 'text-red after:block after:w-[calc(100%-1rem)] after:h-[3px] after:bg-red after:absolute after:-bottom-1'
              : ''
          }`}
          onClick={() => {
            if (active !== links[i].id) {
              console.log(link.id)
              handleMenu(link.id)
              setActive(link.id)
              setOpen(false)
              trackEvent({
                component: 'header',
                event: 'component',
                action: 'click',
                label: link.label,
              })
            }
          }}
        >
          {link.label}
        </button>
      ))}
    </>
  )

  const CTAs = () => (
    <>
      <a
        href="https://www.dow.com/en-us/contact-us.html?cid=WEB:Dow:12844:202302_DCC_Global_DCC_Product_Visualizer:BCI:Global:na:na:na:na:na:DCC:BAC:78c71b6b-a5b6-ed11-83fe-000d3a99361b"
        target="_blank"
        rel="noopener noreferrer"
        className="inline-flex items-center justify-center p-3 ml-5 bg-white text-red leading-none transition-all duration-200 rounded-md hover:bg-red hover:text-white"
        onClick={() => {
          trackEvent({
            component: 'header',
            event: 'link',
            action: 'click',
            label: 'Contact us',
          })
        }}
      >
        <svg
          className="h-4 w-4 mr-2 stroke-current"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
        >
          <path d="M13.5 6.61124L13.5 6.61251C13.5024 7.56017 13.2809 8.49501 12.8537 9.34088L12.8527 9.34274C12.3454 10.3577 11.5655 11.2113 10.6004 11.808C9.63538 12.4047 8.52324 12.721 7.38859 12.7214L7.38749 12.7214C6.43983 12.7238 5.50499 12.5024 4.65912 12.0751L4.47315 11.9812L4.2755 12.0471L0.790541 13.2093L1.95189 9.72439L2.01774 9.5268L1.92383 9.34089C1.49657 8.49501 1.27513 7.56017 1.27754 6.61251L1.27754 6.61145C1.27802 5.4767 1.59444 4.36448 2.19135 3.39941C2.78826 2.43433 3.64208 1.6545 4.65717 1.14729L4.65718 1.1473L4.65912 1.14632C5.50499 0.719053 6.43983 0.49761 7.38749 0.500021H7.38876H7.7635C9.25687 0.585572 10.6668 1.21732 11.7248 2.27525C12.7827 3.33317 13.4144 4.74313 13.5 6.2365L13.5 6.61124Z" />
        </svg>
        Contact us
      </a>
      <div className="inline-flex items-center justify-center p-3 ml-5 text-red leading-none transition-all duration-200 rounded-md">
        <svg
          className="h-4 w-4 fill-current mr-2"
          width="16"
          height="16"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.86387 1.0929C5.88353 1.25424 4.94427 1.62322 4.11102 2.18014C2.96009 2.94938 2.06311 4.04263 1.53349 5.32164C1.24472 6.01901 1.07223 6.75453 1.01887 7.49935H4.57375C4.68807 5.17837 5.48858 2.95006 6.86387 1.0929ZM9.13378 1.09251C10.5093 2.94975 11.3099 5.17821 11.4242 7.49935H14.981C14.8612 5.82578 14.1431 4.24438 12.9488 3.05011C11.9066 2.00792 10.5696 1.32832 9.13378 1.09251ZM10.4229 7.49935C10.2998 5.21388 9.44937 3.02903 7.99897 1.26315C6.54857 3.02903 5.69817 5.21388 5.57508 7.49935H10.4229ZM5.59653 8.49935H10.4014C10.2207 10.6708 9.38301 12.7374 7.99897 14.4225C6.61493 12.7374 5.77724 10.6708 5.59653 8.49935ZM4.59337 8.49935H1.01884C1.0396 8.78923 1.07846 9.07857 1.1356 9.36569C1.4058 10.7234 2.07252 11.9705 3.05146 12.9493C4.0304 13.9281 5.2776 14.5946 6.63534 14.8646C6.80143 14.8976 6.96827 14.9246 7.13552 14.9454C5.65932 13.1025 4.77264 10.8555 4.59337 8.49935ZM8.86209 14.9458C10.3385 13.1028 11.2253 10.8557 11.4046 8.49935H14.9822C14.8957 9.70772 14.4967 10.8761 13.8205 11.8881C13.0514 13.0392 11.9583 13.9363 10.6793 14.4661C10.0947 14.7083 9.48333 14.8688 8.86209 14.9458ZM15.9833 7.49952C15.8619 5.56038 15.0378 3.72492 13.6559 2.343C12.1557 0.842804 10.121 0 7.99938 0H7.99931C6.4172 0.000235319 4.8707 0.469599 3.55534 1.34874C2.23998 2.22788 1.21485 3.47732 0.609566 4.93906C0.00428374 6.4008 -0.153964 8.0092 0.154834 9.56088C0.463631 11.1126 1.22561 12.5378 2.34441 13.6565C3.46321 14.7751 4.88859 15.5368 6.44031 15.8454C7.99203 16.154 9.60041 15.9955 11.0621 15.39C12.5237 14.7845 13.773 13.7592 14.6519 12.4437C15.5309 11.1282 16 9.58162 16 7.99952V7.49952H15.9833Z"
          />
        </svg>
        EMEAI
      </div>
    </>
  )

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <header className="fixed top-0 w-full z-30 bg-white/10 backdrop-blur-lg">
      <div className="px-4 mx-auto sm:px-6 lg:px-8">
        <nav>
          <div className="relative flex items-center justify-between h-16 lg:h-20">
            <div className="hidden lg:flex lg:items-center lg:space-x-4">
              <Links />
            </div>

            <div className="lg:absolute lg:-translate-x-1/2 lg:inset-y-5 lg:left-1/2">
              <div className="shrink-0">
                <img
                  className="w-auto h-8 lg:h-11"
                  src={logo}
                  width="140px"
                  height="44px"
                  alt=""
                />
              </div>
            </div>

            <div>
              <button
                type="button"
                onClick={() => {
                  handleShowSearch(true)
                  trackEvent({
                    component: 'header',
                    event: 'component',
                    action: 'click',
                    label: 'Search',
                  })
                }}
                aria-label="Search"
                className="inline-flex p-3 ml-5 bg-white text-red transition-all duration-200 rounded-md hover:bg-red hover:text-white"
              >
                <svg
                  className="h-4 w-4 fill-current"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2 8.6081C2 4.95855 4.95855 2 8.6081 2C12.2577 2 15.2162 4.95855 15.2162 8.6081C15.2162 12.2577 12.2577 15.2162 8.6081 15.2162C4.95855 15.2162 2 12.2577 2 8.6081ZM8.6081 0C3.85398 0 0 3.85398 0 8.6081C0 13.3622 3.85398 17.2162 8.6081 17.2162C10.3237 17.2162 11.922 16.7143 13.2644 15.8494L17.8497 20.4347L19.2639 19.0205L14.8154 14.572C16.3022 13.0249 17.2162 10.9233 17.2162 8.6081C17.2162 3.85398 13.3622 0 8.6081 0Z"
                  />
                </svg>
              </button>

              <div className="hidden lg:inline-flex">
                <CTAs />
              </div>

              <button
                type="button"
                aria-label="Open Menu"
                onClick={() => {
                  handleClick()
                  trackEvent({
                    component: 'header',
                    event: 'component',
                    action: 'click',
                    label: 'Toggle Menu',
                  })
                }}
                className="inline-flex lg:hidden p-3 ml-5 bg-white text-red transition-all duration-200 rounded-md hover:bg-red hover:text-white"
              >
                <svg
                  className="w-4 h-4"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className={`${open ? 'flex' : 'hidden'} lg:hidden flex-col`}>
            <div className="mb-5 grid grid-cols-2 gap-4">
              <Links />
            </div>
            <div className="-ml-5 mb-5 text-right">
              <CTAs />
            </div>
          </div>
        </nav>
      </div>
    </header>
  )
}

export default Header
