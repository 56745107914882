import { useEffect } from 'react'
import { useTracking } from 'react-tracking'
import image from '../assets/sustainability.png'

interface Props {
  handleShowSustainability: (state: boolean) => void
}

const Sustainability = ({ handleShowSustainability }: Props) => {
  const { trackEvent } = useTracking()

  useEffect(() => {
    const close = (e: { key: string }) => {
      if (e.key === 'Escape') {
        handleShowSustainability(false)
      }
    }
    window.addEventListener('keydown', close)

    return () => window.removeEventListener('keydown', close)
  }, [])

  const handleClick = () => {
    handleShowSustainability(false)
    trackEvent({
      component: 'sustainability',
      event: 'component',
      action: 'click',
      label: 'close',
    })
  }

  return (
    <div className="absolute top-0 left-0 z-20 w-screen min-h-screen bg-white/60 backdrop-blur-lg">
      <div className="container mx-auto px-4 my-16 pt-10">
        <div className="grid grid-cols-1 gap-10">
          <div className="mb-4">
            <button
              className="text-lg hover:underline flex flex-row items-center mb-10"
              onClick={handleClick}
            >
              <svg
                className="inline-block mr-2"
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M7 11L2 6L7 1" stroke="#E80033" strokeWidth="2" />
              </svg>
              Back
            </button>

            <img className="w-full" src={image} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sustainability
