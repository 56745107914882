/* eslint-disable @typescript-eslint/restrict-template-expressions */
import {
  Header,
  Search,
  Modal,
  Intro,
  Warning,
  Sustainability,
} from './components'
import { Unity, useUnityContext } from 'react-unity-webgl'
import { useEffect, useState, useCallback } from 'react'
import { useTracking } from 'react-tracking'
import { isDesktop } from 'react-device-detect'

type Tracking = {
  page: string
  component: string
  event: string
  action: string
  label: string
}

function App() {
  const UNITYBUILD = isDesktop
    ? process.env.REACT_APP_UNITYBUILD_HIGHRES
    : process.env.REACT_APP_UNITYBUILD
  const UNITYFOLDER = isDesktop
    ? process.env.REACT_APP_UNITYFOLDER_HIGHRES
    : process.env.REACT_APP_UNITYFOLDER
  const [showIntro, setShowIntro] = useState(true)
  const [showSearch, setShowSearch] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showSustainability, setShowSustainability] = useState(false)
  const [category, setCategory] = useState(0)

  const { Track, trackEvent } = useTracking<Tracking>({ page: '3D House' })

  const {
    unityProvider,
    isLoaded,
    loadingProgression,
    sendMessage,
    addEventListener,
    removeEventListener,
  } = useUnityContext({
    loaderUrl: `${UNITYFOLDER}/build/${UNITYBUILD}.loader.js`,
    dataUrl: `${UNITYFOLDER}/build/${UNITYBUILD}.data.br`,
    frameworkUrl: `${UNITYFOLDER}/build/${UNITYBUILD}.framework.js.br`,
    codeUrl: `${UNITYFOLDER}/build/${UNITYBUILD}.wasm.br`,
    streamingAssetsUrl: `${UNITYFOLDER}/StreamingAssets`,
    companyName: 'Dow',
    productName: '3D House',
    productVersion: '1.0.0',
    webglContextAttributes: {
      alpha: true,
      antialias: true,
      depth: true,
      failIfMajorPerformanceCaveat: true,
      powerPreference: 'high-performance',
      premultipliedAlpha: true,
      preserveDrawingBuffer: true,
      stencil: true,
      desynchronized: true,
      xrCompatible: true,
    },
  })

  const handleShowIntro = (state: boolean) => {
    setShowIntro(state)
  }

  const handleMenu = (id: number) => {
    sendMessage('MainCam', 'SetTarget', id)
    setCategory(0)
    setShowModal(false)
    setShowSearch(false)
    setShowSustainability(false)
  }

  const handleShowSearch = (state: boolean) => {
    setShowSearch(state)
  }

  const handleShowModal = (state: boolean) => {
    setShowModal(state)
  }

  const handleShowSustainability = (state: boolean) => {
    setShowSustainability(state)
  }

  const handleUnitySustainability = useCallback(() => {
    trackEvent({
      component: 'sustainability',
      event: 'component',
      action: 'click',
      label: `open`,
    })
    handleShowSustainability(true)
  }, [])

  const handleUnitySegment = useCallback((name: string) => {
    trackEvent({
      component: 'segment',
      event: 'component',
      action: 'click',
      label: `segment :: ${name}`,
    })
  }, [])

  const handleUnityCategory = useCallback((name: string, id: string) => {
    trackEvent({
      component: 'category',
      event: 'component',
      action: 'click',
      label: `category :: ${name}`,
    })
    setCategory(parseInt(id, 10))
    setShowModal(true)
  }, [])

  useEffect(() => {
    addEventListener('SegmentClicked', handleUnitySegment)
    addEventListener('CategoryClicked', handleUnityCategory)
    addEventListener('SegmentClickedSingleArg', handleUnitySustainability)
    return () => {
      removeEventListener('SegmentClicked', handleUnitySegment)
      removeEventListener('CategoryClicked', handleUnityCategory)
      removeEventListener('SegmentClickedSingleArg', handleUnitySustainability)
    }
  }, [
    handleUnitySegment,
    handleUnityCategory,
    handleUnitySustainability,
    addEventListener,
    removeEventListener,
  ])

  return (
    <Track>
      {isLoaded && (
        <Header handleMenu={handleMenu} handleShowSearch={handleShowSearch} />
      )}

      <main className="absolute top-0 left-0 w-screen h-screen">
        {showIntro && (
          <Intro
            isLoaded={isLoaded}
            loadingProgression={loadingProgression}
            handleShowIntro={handleShowIntro}
          />
        )}
        {!showIntro && <Warning />}
        {showSearch && <Search handleShowSearch={handleShowSearch} />}
        {showModal && <Modal id={category} handleShowModal={handleShowModal} />}
        {showSustainability && (
          <Sustainability handleShowSustainability={handleShowSustainability} />
        )}
        <Unity
          unityProvider={unityProvider}
          className={`fixed z-10 w-screen h-screen focus:outline-none ${
            isLoaded ? '' : 'hidden'
          }`}
        />
      </main>
    </Track>
  )
}

export default App
