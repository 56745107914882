import Fuse from 'fuse.js'
import { useEffect, useRef, useState } from 'react'
import products from '../data/products.json'
import Card from './card'
import { useTracking } from 'react-tracking'

interface Product {
  id: number
  name: string
  innovation: boolean
  segment: string
  segmentId: number
  category: string
  categoryId: number
  subCategory: string
  description: string
  url: string
  image: string
  meta: string
}

type Result = {
  item: Product
}[]

interface Props {
  handleShowSearch: (state: boolean) => void
}

const Search = ({ handleShowSearch }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [searchInput, setSearchInput] = useState('')
  const [results, setResults] = useState<Result>([])
  const { trackEvent } = useTracking()

  const fuse = new Fuse(products, {
    includeScore: true,
    threshold: 0.2,
    keys: ['name', 'segment', 'category', 'subcategory', 'meta'],
  })

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  useEffect(() => {
    const close = (e: { key: string }) => {
      if (e.key === 'Escape') {
        handleShowSearch(false)
      }
    }
    window.addEventListener('keydown', close)

    return () => window.removeEventListener('keydown', close)
  }, [])

  const handleClose = () => {
    handleShowSearch(false)
    trackEvent({
      component: 'search',
      event: 'component',
      action: 'click',
      label: 'close',
    })
  }

  const handleChange = (e: {
    preventDefault: () => void
    target: { value: string }
  }) => {
    setSearchInput(e.target.value)

    trackEvent({
      component: 'search',
      event: 'component',
      action: 'search',
      label: `query :: ${e.target.value}`,
    })

    if (e.target.value.length === 0) {
      setResults([])
    }

    if (e.target.value.length > 2) {
      const query = fuse.search(e.target.value)
      setResults(query)
      trackEvent({
        component: 'search',
        event: 'component',
        action: 'search',
        label: `results :: ${query.length} :: ${e.target.value}`,
      })
    }
  }

  return (
    <div className="bg-gray text-black flex flex-col items-center w-screen min-h-screen absolute top-0 left-0 z-50">
      <button
        className="absolute top-5 right-5 p-2"
        type="button"
        onClick={handleClose}
        aria-label="Close"
      >
        <svg
          width="29"
          height="29"
          viewBox="0 0 29 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.4362 12.3149L2.24562 0.124304L0.1243 2.24563L12.3149 14.4362L0.124302 26.6267L2.24562 28.748L14.4362 16.5575L26.6267 28.748L28.748 26.6267L16.5575 14.4362L28.748 2.24562L26.6267 0.124302L14.4362 12.3149Z"
            fill="#E80033"
          />
        </svg>
      </button>
      <div className="container mx-auto px-4 my-16">
        <div className="flex flex-col items-center mb-16">
          <h2 className="font-serif text-5xl mb-4">Search Products</h2>
          <p className="font-sans text-lg mb-6 text-center">
            Search for products and innovations showcased throughout the Visual
            Product Finder experience
          </p>
          <div className="pt-2 relative mx-auto w-full max-w-6xl">
            <input
              ref={inputRef}
              className="border-2 border-darkGray bg-white h-16 w-full px-5 pr-16 rounded-md"
              type="text"
              name="search"
              onChange={handleChange}
              value={searchInput}
              placeholder="What are you looking for?"
            />
            <button type="button" className="absolute right-0 top-0 mt-7 mr-4">
              <svg
                className=" h-6 w-6 fill-current"
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2 8.6081C2 4.95855 4.95855 2 8.6081 2C12.2577 2 15.2162 4.95855 15.2162 8.6081C15.2162 12.2577 12.2577 15.2162 8.6081 15.2162C4.95855 15.2162 2 12.2577 2 8.6081ZM8.6081 0C3.85398 0 0 3.85398 0 8.6081C0 13.3622 3.85398 17.2162 8.6081 17.2162C10.3237 17.2162 11.922 16.7143 13.2644 15.8494L17.8497 20.4347L19.2639 19.0205L14.8154 14.572C16.3022 13.0249 17.2162 10.9233 17.2162 8.6081C17.2162 3.85398 13.3622 0 8.6081 0Z"
                  fill="#2C2A29"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
          {results.map((result) => (
            <Card key={result.item.id} product={result.item} type="search" />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Search
